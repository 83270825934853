import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClearingTicket, ClearingTicketService } from '@adlatus-gui/domain/clearing';

@Injectable({
  providedIn: 'root'
})
export class ClearingTicketUtils {
  constructor(private clearingTicketService: ClearingTicketService) {
  }

  listClearingTicketByProcessor(processor: string, offset: number, limit: number): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      undefined,  // originator
      processor,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      offset,     // offset
      limit,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  listClearingTicketByOriginator(originator: string, offset: number, limit: number): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      originator,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      offset,     // offset
      limit,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

   inboxClearingTickets(processor: string): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      undefined,  // originator
      processor,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      0,     // offset
      10000,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  outboxClearingTickets(originator: string): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      originator,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      0,     // offset
      10000,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }
}
